import {
  withScope,
  EventHint,
  Event,
  User,
  captureException as sentryCaptureException,
} from '@sentry/remix';
import { Context, Primitive } from '@sentry/types';
import type { Request } from 'express';
import { requireEnv } from './env';
import { NestedError } from './nested-error';
import { HttpApiRequestError } from '../apiWebbase/HttpApiRequestError';

export const SENTRY_ADDON_DSN = requireEnv('SENTRY_ADDON_DSN');

const isServer = typeof window === 'undefined';

export type SentryOptions = {
  fingerprint?: string[];
  user?: User;
  tags?: { [key: string]: Primitive };
};

export type ResponseSummary = {
  status: number;
  statusText: string;
  contentType: string | undefined;
  body: string | object | undefined;
};

export type RequestSummary = {
  url: string;
  method: string | undefined;
  params: unknown;
  data: string | undefined;
};

const getRequest = (error: unknown): RequestSummary | string | undefined => {
  if (error instanceof HttpApiRequestError) {
    return error.request;
  }

  return undefined;
};

const getResponse = async (
  error: unknown
): Promise<ResponseSummary | undefined> => {
  if (error instanceof Response) {
    return {
      status: error.status,
      statusText: error.statusText,
      contentType: error.headers.get('Content-Type') || undefined,
      body: await error.text(),
    };
  }
  if (error instanceof HttpApiRequestError) {
    return {
      status: error.statusCode,
      statusText: error.statusText,
      body: error.content,
      contentType: undefined,
    };
  }
  /* TODO? what is the equivelant in remix world?
  return error instanceof Error && isAxiosError(error) && error.response
    ? Promise.resolve(error.response.data)
    : Promise.resolve(undefined);
    */

  return Promise.resolve(undefined);
};

export function createRemixHeaders(
  requestHeaders: Request['headers']
): Headers {
  const headers = new Headers();

  for (const [key, values] of Object.entries(requestHeaders)) {
    if (values) {
      if (Array.isArray(values)) {
        for (const value of values) {
          headers.append(key, value);
        }
      } else {
        headers.set(key, values);
      }
    }
  }

  return headers;
}

const getErrorName = (error: unknown) => {
  try {
    if (error instanceof Error) {
      return error.name !== 'Error'
        ? error.name
        : error.constructor.name || error.name;
    }
  } catch (_) {
    // do nothing
  }
  return undefined;
};

// When errors are displayed (serialized) at Sentry
// they usually don't include message or name... because reasons?
// So if we add them here as simple properties then they show up
const describeError = (error: unknown) => {
  if (error instanceof NestedError) {
    // cause NestedError.originalStack to be excluded from Sentry's purview
    return {
      ...error.toJSON(),
      name: getErrorName(error),
      message: error.message,
    };
  }
  if (error instanceof Error) {
    return {
      ...error,
      name: getErrorName(error),
      message: error.message,
    };
  }

  return error;
};

export const beforeSend = async (
  event: Event,
  hint: EventHint
): Promise<Event | null> => {
  const error = hint.originalException;
  const rootCause =
    error instanceof NestedError ? error.getRootCause() : undefined;
  const innermostError = rootCause || error;
  const response = await getResponse(innermostError);
  const request = getRequest(innermostError);

  return {
    ...event,
    extra: {
      ...event.extra,
      Error: describeError(error),
      ...(request ? { Request: request } : {}),
      ...(response ? { Response: response } : {}),
      ...(rootCause && { RootCause: describeError(rootCause) }),
    },
  };
};

export function captureException(
  error: unknown,
  context: Context = {},
  options: SentryOptions = {}
) {
  const { fingerprint, user, tags } = options;
  let rv: string | undefined;
  if (error === null) {
    console.error(`Null error passed to captureException()`);
    return rv;
  }

  // NestedError's stacks are each nested, containing the stack of all errors below
  // But Sentry displays each error and its own stack separately, so we end up repeating the stacks over and over
  // This is a workaround to remove the repeated stacks
  // Update: It turns out Sentry doesn't actually use the stack trace from the other errors, so lets not unnest after all
  /* if (error && error instanceof NestedError) {
    error.unstackAll();
  } */

  withScope((scope) => {
    if (user) scope.setUser(user);
    if (fingerprint) scope.setFingerprint(fingerprint);
    if (tags) scope.setTags(tags);
    scope.setExtra('Error-Context', { ...context, server: isServer });

    rv = sentryCaptureException(error);
  });

  return rv;
}
