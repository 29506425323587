import { NestedError } from '../lib/nested-error';

/**
 * Represents an error that we encountered talking to our server (eg a network issue, or bug in client side code, or server failing to process the request)
 *
 * Note: Is not used for errors that happen while the server is talking to a 3rd party api, those are in ConnectionError
 */
export class ApiRequestError extends NestedError {
  constructor(message: string, nested?: Error) {
    super(message, nested);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiRequestError.prototype);
  }
}
