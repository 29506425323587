import { captureException } from './sentry';

export const WebAppName = 'web';

export enum App {
  LookerStudio = 'ds',
  SheetsAddonGeneral = 'sheets-addon:general',
}

declare global {
  interface Window {
    env: { [key: string]: string | undefined };
  }
}

export const SW_HOST = 'syncwith.com';

function getCtx() {
  return typeof window !== 'undefined' ? window.env : process.env;
}

export const getEnv = (name: string) => {
  return getCtx()[name];
};

export function requireEnv(name: string): string {
  const ctx = getCtx();
  if (name in ctx) {
    return ctx[name] as string;
  }

  throw new Error(`No ENV variable named ${name} found`);
}

export const getApp = (): 'crypto' | 'syncwith' => {
  return 'syncwith';
};

export const APP_HOST = SW_HOST;

export const isGoogleSheetsAddonApp = (app: string): boolean =>
  app ? app.startsWith('sheets-addon:') : false;

export const getAppLabel = (app: string): string => {
  if (app === 'shopify-reports') return 'Shopify Reports';
  if (app === 'airtable') return 'Airtable';
  if (app === 'ds') return 'Google Looker Studio';
  if (app === 'excel') return 'Microsoft Excel';
  if (app === 'powerbi') return 'Power BI';
  if (app === 'web') return 'Web';
  if (app === 'sheets-addon:fb') return 'Facebook connector for Google Sheets';
  if (app === 'sheets-addon:general') return 'SyncWith for Google Sheets';
  if (app === 'sheets-addon:dbs') return 'Database connector for Google Sheets';
  if (app === 'sheets-addon:google-ads')
    return 'Google Ads connector for Google Sheets';
  if (app === 'sheets-addon:google-analytics')
    return 'Google Analytics connector for Google Sheets';
  if (app === 'sheets-addon:crypto')
    return 'Crypto Coin Tracker for Google Sheets';
  if (app === 'sheets-addon:ecommerce')
    return 'Shopify, Stripe, WooCommerce addon';
  if (app === 'sheets-addon:crm') return 'Salesforce, Hubspot, Asana CRM data';
  if (app === 'sheets-addon:task-management')
    return 'Airtable, Trello, Zendesk management connector';
  if (app === 'sheets-addon:seo') return 'SEO data for Google Sheets';
  if (app === 'sheets-addon:dev') return 'Jira, Github project management';
  if (app === 'sheets-addon:ads')
    return 'Microsoft Bing, LinkedIn, Pinterest ads data';
  if (app === 'sheets-addon:finance')
    return 'Xero, Quickbooks, Netsuite finance data';
  if (app === 'sheets-addon:social') return 'Twitter ads, YouTube social data';
  if (app === 'sheets-addon:project-management')
    return 'Notion, Monday project management data';

  captureException(new Error(`No label found for app: ${app}`));
  return app;
};

export const getProductionHostname = (): string => SW_HOST;

export const getHostNameIncludingPort = (): string => {
  return process.env.NODE_ENV === 'production'
    ? getProductionHostname()
    : 'localhost:3000';
};

export const getProductionAbsoluteUrl = (relativeUrl: string) => {
  return `https://${getProductionHostname()}${relativeUrl}`;
};

export const getAbsoluteUrl = (relativeUrl: string) => {
  const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
  return `${protocol}://${getHostNameIncludingPort()}${relativeUrl}`;
};

export const getAppName = (): string => 'SyncWith';

export function assertIsError(err: unknown): asserts err is Error {
  // if you have nodejs assert:
  // assert(error instanceof Error);
  // otherwise
  if (!(err instanceof Error)) {
    throw err;
  }
}
