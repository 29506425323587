import { RequestSummary } from '../lib/sentry';
import { ApiRequestError } from './ApiRequestError';

/** Represents an HTTP error that occurs talking to our server (not a 3rd party API), eg a 500 or 404 */
export class HttpApiRequestError extends ApiRequestError {
  readonly statusCode: number;

  readonly statusText: string;

  readonly content: string | Record<string, unknown>;

  readonly request: RequestSummary;

  constructor(
    message: string,
    request: RequestSummary,
    statusCode: number,
    statusText: string,
    content: string | Record<string, unknown>
  ) {
    super(message);

    this.statusCode = statusCode;
    this.statusText = statusText;
    this.content = content;
    this.request = request;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpApiRequestError.prototype);
  }
}
